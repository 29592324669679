.password-container {
  position: relative;
  // width: 300px;
}

.password-input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
}

.pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;
  max-width: 1300px;
  margin: 0 auto;
}
.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 30px;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}
table th {
  font-weight: 500;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.selected {
  color: black;
  background-color: rgb(62, 243, 62);
}